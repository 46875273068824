import { render, staticRenderFns } from "./DocTable.vue?vue&type=template&id=5d9e3181&scoped=true&"
import script from "./DocTable.vue?vue&type=script&lang=js&"
export * from "./DocTable.vue?vue&type=script&lang=js&"
import style0 from "./DocTable.vue?vue&type=style&index=0&id=5d9e3181&prod&scoped=true&lang=css&"
import style1 from "./DocTable.vue?vue&type=style&index=1&id=5d9e3181&prod&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5d9e3181",
  null
  
)

export default component.exports